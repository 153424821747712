@import '../../styles/global.scss';
.slider-swiper-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: auto;
  background: white;
  .before {
    position: absolute;
    top: -300px;
    height: 400px;
    width: 100%;
    background: $theme-color;
  }
  .slider-container {
    position: relative;
    width: 98%;
    // height: 160px;
    overflow: hidden;
    margin: auto;
    border-radius: 6px;
    .slider-nav {
      //   position: absolute;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.slick-write {
  bottom: 0;
  li.slick-active {
    button {
      &:before {
        color: $theme-color;
      }
    }
  }
}
